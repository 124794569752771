









































































































































































































































import type { BvModalEvent } from 'bootstrap-vue';
import { Component } from 'vue-property-decorator';
import i18nDb from '@/services/i18n-db';
import { store } from '../common';
import { CollapsiblePane, ComponentBase, ListItem, ListItemPart, Overlay, SvgIcon } from '../components';
import I18n from '../I18n';
import i18n from "@/services/i18n";
import {Org, Report2, Utils, Version} from '../types';
import type { BoolEx, ReportSelection } from './types';
import GenerationPane from './GenerationPane.vue';
import FilterSortPane from './FilterSortPane.vue';
import ReportListPane from './ReportListPane.vue';
import ReportSelectionPane from './ReportSelectionPane.vue';
import TemplateKey = Utils.TemplateKey;
import ConfirmModal from "@/modules/budget/staffing-table/components/ConfirmModal.vue";


const translates = {
    tools: {
        get generateReportBut(): string {
            return i18n.choose(
                'Сформировать',
                'Жасау',
                'Generate',
            );
        },
        get filterBut(): string {
            return i18n.choose(
                'Перечень отчетов',
                'Есептер тізімі',
                'List of Reports',
            );
        },
        get sendToTotalBut(): string {
            return i18n.choose(
                'Отправить в свод',
                'Жиынтыққа жіберу',
                'Submit to summary',
            );
        }
    },
    error: {
        get cannotSendToTotal(): string {
            return i18n.choose(
                'Не удалось отправить в свод',
                'Не удалось отправить в свод (каз)',
                'Cannot send to total',
            );
        },
        get cannotRemoveToReports(): string {
            return i18n.choose(
                'Не удалось удалить отчеты',
                'Есептерді жою мүмкін болмады',
                'Cannot remove reports',
            );
        },
    },
    sendToTotal: {
        get success(): string {
            return i18n.choose(
                'Отправлено в свод',
                'Отправлено в свод (каз)',
                'Sent to total',
            );
        },
        get title(): string {
            return i18n.choose(
                'Отправить в свод',
                'Отправить в свод (каз)',
                'Send to total',
            );
        },
    },
    removeToReports: {
        get success(): string {
            return i18n.choose(
                'Отчеты удалены',
                'Есептер жойылды',
                'Reports are deleted',
            );
        },
        get title(): string {
            return i18n.choose(
                'Удалить отчет',
                'Есепті жою',
                'Delete report',
            );
        },
         confirmation(Ids: string): string {
            return i18n.choose(
                `Список отчетов на удаление: (${Ids})`,
                `Жоюға арналған есептер тізімі: (${Ids})`,
                `List of reports for deletion: (${Ids})`,
            );
        },
    },
    templateTitles: {
        title(key: TemplateKey | null): string {
            switch (key) {
                case "variant-budget-reports": {
                    return i18n.choose(
                        'Отчеты по варианту бюджета',
                        'Бюджет нұсқасы бойынша есептер',
                        'Reports by Budget Variant',);
                }
                case "variant-budget-staff-version-reports": {
                    return i18n.choose(
                        'Отчеты по версии штатного расписания и варианту бюджета',
                        'Штаттық кесте нұсқасы мен бюджет нұсқасына қатысты есептер',
                        'Reports by staffing table version and budget variant');
                }
                case "budget-program-reports":{
                    return i18n.choose(
                        'Отчеты по бюджетной программе',
                        'Бюджеттік бағдарлама бойынша есептер',
                        'Reports on the budget program',);
                }
                case "specificity-reports":{
                    return i18n.choose(
                        'Отчеты по спецификам',
                        'Ерекшеліктер бойынша есептер',
                        'Reports by Specifics');
                }
                case "form-reports":{
                    return i18n.choose(
                        'Отчеты по формам',
                        'Формалар бойынша есептер',
                        'Reports by Forms');
                }
                default: {
                    return i18n.choose(
                        'Шаблон не выбран',
                        'Үлгі таңдалмады',
                        'Template not selected');
                }

            }
        }
    },
};


// region Types
interface IReportListPane {
    reloadReports(): void;
}


interface IReportSendToTotalSelection {
    reportId: number;
    year: number;
}
// endregion


const i18nT = new I18n('modules.budget.staffing_table.reports');


@Component({
    components: {
        CollapsiblePane,
        FilterSortPane,
        GenerationPane,
        ListItem,
        ListItemPart,
        Overlay,
        ReportListPane,
        ReportSelectionPane,
        SvgIcon,
        ConfirmModal
    },
})
export default class Page extends ComponentBase {
    constructor() {
        super('');
    }


    // region Lifecycle
    protected created() {
        // region Организация, версия ШР
        this.$watch('selectedOrg', (selectedOrg: Org | null) => {
            store.org = selectedOrg;
        });
        this.$watch('selectedVersion', (selectedVersion: Version | null) => {
            store.version = selectedVersion;
        });
        // endregion

        this.$watch('templateKey', (templateKey: TemplateKey | null) => {
            store.templateKey = templateKey;
        });
        // endregion
    }

    private confirmModalVisible: boolean = false;

    private showConfirmModal() {
        this.confirmModalVisible = true;
    }

    private closeConfirmModal() {
        this.confirmModalVisible = false;
    }

    removeReports() {
        const reportIds = this.selectedReports.map(value => value.id);

        if (reportIds.length === 0) {
            return;
        }

        this.request(
            {
                url: `/api/budget/staffing_table/report-v2/delete-to-reports`,
                method: 'POST',
                data: reportIds,
            },
            () => {
                this.toast('success', '', translates.removeToReports.success);
                this.reloadReports()

            },
            () => (translates.error.cannotRemoveToReports),
            () => {
                this.closeConfirmModal()
            },
        );
    }
    // endregion


    // region Утилиты
    public translates = translates;
    public i18n = i18nT;
    public i18nDb = i18nDb;
    // endregion


    // region Генерация отчетов
    public generationModalVisible = false;
    public lastReportEventTime = 0;

    // noinspection JSUnusedLocalSymbols
    public onReportSaved(report: Report2) {
        this.toast(
            'success',
            '',
            this.i18nDb.translateByKey('modules.budget.staffing-table.reports.*GenerationPane*.report-saved', report.id),
        );

        this.lastReportEventTime = Date.now();
        this.generationModalVisible = false;
    }
    // endregion


    // region Фильтрация, сортировка
    public filterSortModalVisible = false;
    public templateKey = store.templateKey;
    public filterData = store.conditionExp;

    public handleUpdateCondition(filterData: BoolEx.Expression<boolean> | null, templateKey: TemplateKey) {
        this.templateKey = templateKey;
        this.filterData = filterData;
        if (filterData !== null){
            this.filterSortModalVisible = false;
        }
    }
    // endregion


    // region Список отчетов
    public selectedReports: Array<Report2> = [];

    get selectedIds(): string{
        return this.selectedReports.map(report => report.id).join(',');
    }

    public reloadReports() {
        (this.$refs.reportListPane as unknown as IReportListPane).reloadReports();
    }

    public onReportSelectionChanged(reports: Array<Report2>) {
        this.selectedReports = reports;
    }
    // endregion


    // region Отправка в свод
    public sendToTotalModalLoading = false;
    public sendToTotalModalVisible = false;
    public sendToTotalModalSelections: Array<ReportSelection> = [];

    public showSendToTotalModal() {
        this.sendToTotalModalSelections = [];
        this.sendToTotalModalVisible = true;
    }

    public onSendToTotalModalSelectionsChanged(...args: Array<unknown>) {
        this.sendToTotalModalSelections = args[0] as Array<ReportSelection>;
    }

    public onSendToTotalModalOkClicked(...args: Array<unknown>) {
        if (this.sendToTotalModalLoading) {
            console.error('Cannot send to total - another loading is running');
            return;
        }

        const selections = this.sendToTotalModalSelections.map((selection): IReportSendToTotalSelection => {
            return {
                reportId: selection.report.id!!,
                year: selection.year,
            };
        });
        if (selections.length === 0) {
            console.error('Cannot send to total - no selections');
            return;
        }

        const event: BvModalEvent = args[0] as BvModalEvent;
        event.preventDefault();

        this.sendToTotalModalLoading = true;
        this.request(
            {
                url: `/api/budget/staffing_table/report-v2/send-to-total`,
                method: 'POST',
                data: selections,
            },
            () => {
                this.toast('success', '', translates.sendToTotal.success);
                this.sendToTotalModalVisible = false;
            },
            () => (translates.error.cannotSendToTotal),
            () => {
                this.sendToTotalModalLoading = false;
                this.reloadReports()
            },
        );
    }
    // endregion


    // region Пакетная выгрузка
    public downloadReportPackageTest1(aggregated: boolean) {
        if (this.selectedReports.length === 0) {
            console.error('Report package downloading cancelled - no selected reports');
            return;
        }

        const idList = this.selectedReports.map((report) => (report.id!!));

        const parameterMap: Array<[string, unknown]> = [
            ['aggregate', String(aggregated)],
        ];
        idList.forEach((id) => {
            parameterMap.push(['report-id', String(id)]);
        });

        const url = this.prepareUrl(
            '/api/budget/staffing_table/report-v2/package/test-1',
            parameterMap,
        );

        this.openUrl(url, '_blank', false);
    }


    public reportPackageTest2ModalVisible = false;
    public reportPackageTest2GuIdString = '';
    public reportPackageTest2KgkpIdString = '';
    public reportPackageTest2Aggregate = false;

    public get reportPackageTest2GuIdList(): Array<number> {
        return this.reportPackageTest2GuIdString
            .split(',')
            .map((part) => (parseInt(part.trim())))
            .filter((id) => (Number.isSafeInteger(id)))
    }

    public get reportPackageTest2KgkpIdList(): Array<number> {
        return this.reportPackageTest2KgkpIdString
            .split(',')
            .map((part) => (parseInt(part.trim())))
            .filter((id) => (Number.isSafeInteger(id)))
    }

    public showReportPackageTest2Modal() {
        this.reportPackageTest2ModalVisible = true;
    }

    public downloadReportPackageTest2() {
        const parameterMap: Array<[string, unknown]> = [
            ['aggregate', String(this.reportPackageTest2Aggregate)],
        ];
        this.reportPackageTest2GuIdList.forEach((id) => {
            parameterMap.push(['gu-total-id', String(id)]);
        });
        this.reportPackageTest2KgkpIdList.forEach((id) => {
            parameterMap.push(['kgkp-total-id', String(id)]);
        });

        const url = this.prepareUrl(
            '/api/budget/staffing_table/report-v2/package/test-2',
            parameterMap,
        );

        this.openUrl(url, '_blank', false);
    }
    // endregion
}
